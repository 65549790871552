<template>
  <!-- Details step -->
  <mem-button
    v-if="step === 'details'"
    :loading="loading"
    @click="$emit('on-submit')"
    >{{ $t("cart_total_checkout") }}</mem-button
  >
  <mem-button
    v-else-if="step === 'extras'"
    :loading="loading"
    @click="$emit('on-submit')"
  >
    {{ $t("payment") }}
  </mem-button>
  <!-- Other steps -->
  <div v-else class="checkout-button-wrap">
    <!-- Sezzle button -->
    <template v-if="sezzlePayment && !killerDeal">
      <div v-if="loading" class="button-loader">
        <v-progress-circular indeterminate color="black" />
      </div>
      <div
        id="sezzle-smart-button-container"
        height="44px"
        sezzleImagePositionTop="0px"
        sezzleImagePositionLeft="10px"
        :class="{ 'is-hidden': loading }"
        :templateText="`${$t('cart_total_pay_sezzle')} %%logo%%`"
      ></div>
    </template>

    <mem-button v-else :loading="loading" @click="$emit('on-submit')">
      <span v-if="killerDeal">{{ $t("cart_total_confirm") }}</span>
      <span v-else>{{ $t("cart_total_pay") }}</span>
    </mem-button>
  </div>
</template>
<script>
import config from "@/config";
import { mapGetters } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    testCard: {
      cardCvv: "451",
      cardExpMonth: "11",
      cardExpYear: "2030",
      cardPan: "4242424242424242",
    },
  }),
  props: {
    step: String,
    loading: Boolean,
    paymentCheckbox: Boolean,
    killerDeal: Boolean,
    sezzlePayment: Boolean,
  },
  computed: {
    ...mapGetters(["orderDetails", "checkoutParams"]),
  },
  watch: {
    sezzlePayment: {
      handler(val) {
        if (!val) return;
        this.$nextTick(() => {
          this.initSezzleButton();
        });
      },
    },
  },
  methods: {
    initSezzleButton() {
      if (!this.sezzlePayment) return;

      let sezzleButton = document.getElementById("sezzle-smart-button");
      if (sezzleButton) return;

      const checkout = new Checkout(config.sezzleConfig);
      checkout.renderSezzleButton("sezzle-smart-button-container");
      this.$nextTick(() => {
        checkout.init({
          onClick: () => {
            //
            this.$emit("on-sezzle-init");
            if (!this.paymentCheckbox) return;

            checkout.startCheckout({
              checkout_payload: {
                amount_in_cents: this.orderDetails.total_cents,
                currency: this.checkoutParams.currency,
              },
            });
          },
          onComplete: async (event) => {
            let { card } = event.data;
            if (!card) return console.log("Card data is missing");
            // staging
            if (config.sezzleConfig.apiMode === "sandbox")
              return this.$emit("on-sezzle-complete", this.testCard);

            this.$emit("on-sezzle-complete", {
              cardCvv: card.cvv,
              cardExpMonth: card.expiryMonth,
              cardExpYear: card.expiryYear,
              cardPan: card.pan,
            });
          },
          onCancel: async () => {},
          onFailure: () => {
            this.$emit("on-sezzle-error", {
              title: this.$t("checkout_error_payment_declined"),
            });
          },
        });
      });
    },
  },
  created() {
    this.$nextTick(() => {
      let sezzleButton = document.getElementById("sezzle-smart-button");
      if (this.sezzlePayment && !sezzleButton) this.initSezzleButton();
    });
  },
};
</script>
<style lang="scss" scoped>
.checkout-button-wrap {
  display: grid;
  grid-template-columns: 1fr;
  .button-loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
#sezzle-smart-button-container {
  display: grid;
  grid-template-columns: 1fr;
  &.is-hidden {
    display: none;
  }
}
</style>